// Third party
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import uuid from 'react-uuid';
import Cookies from 'universal-cookie';

// Redux
import { useDispatch } from "react-redux";

// Api
import axios from "axios";

// Reducer
import { setAuthData } from "../Store/Reducer/Authentication/Authentication";

// Helper
import { baseURL, getAuth } from "../Utils";

const useAxios = () => {
  const authTokens = getAuth();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: "Bearer " + authTokens?.access },
  });

  axiosInstance.interceptors.request.use(async (req) => {
    const user = jwt_decode(authTokens.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs(), "minute") < 1;

    if (!isExpired) return req;
    let web_cookie;
    if(cookies.get('device_id') === undefined || cookies.get('device_id') === '' || cookies.get('device_id') === null) {
      web_cookie = uuid()
      cookies.set('device_id', JSON.stringify(web_cookie));
    }else{
      web_cookie = cookies.get('device_id')
    }
    const response = await axios
      .post(`${baseURL}/token/refresh/`, {
        refresh: authTokens.refresh,
        web_cookie:web_cookie
      })
      .catch(function (error) {
        console.log("error", error);
        return req;
      });

    const old_auth = getAuth();
    const auth = { ...old_auth, ...response.data.data };

    localStorage.setItem("auth", JSON.stringify(auth));
    dispatch(setAuthData(response.data.data));

    req.headers.Authorization = "Bearer " + response.data.data.access;
    return req;
  });

  axiosInstance.interceptors.response.use(
    function (success) {
      return success;
    },
    function (error) {
      // console.log(Promise.reject(error));
      if (error?.response?.status === 401) {
        localStorage.clear();
        navigate("/login");
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxios;
